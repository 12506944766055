<template>
  <div class="validator-holder" v-if="true">
    <card
      :title="$t('tools.import_validator.title')"
      :title-bottom-padding="'10px'"
      id="import-validator"
    >
      <DxDataGrid
        id="gridContainer"
        ref="dataGrid"
        keyExpr="uuid"
        :data-source="gridData"
        :columns="gridColumns"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :filter-row="{ visible: true, applyFilter: 'auto' }"
        :selection="{ mode: 'multiple', showCheckBoxesMode: 'always' }"
        :column-min-width="150"
        :selected-row-keys="selectedRowKeys"
        :allow-column-reordering="true"
        :column-auto-width="true"
        :repaint-changes-only="true"
        @selection-changed="onSelectionChanged($event)"
        @saving="onSaving"
        @exporting="onExporting"
        :height="600"
      >
        <DxHeaderFilter :visible="true" />
        <DxPaging :enabled="true" :pageSize="100" />
        <DxPager
          :visible="true"
          :showPageSizeSelector="true"
          :allowedPageSizes="[23, 100, 500]"
          :showInfo="true"
        />
        <DxSearchPanel :visible="true" :width="240" />
        <DxSelection
          select-all-mode="allMode"
          show-check-boxes-mode="always"
          mode="multiple"
        />
        <DxEditing
          :allow-updating="true"
          :allow-adding="false"
          :allow-deleting="true"
          :select-text-on-edit-start="true"
          :start-edit-action="'click'"
          mode="batch"
        />
        <DxLoadPanel
          :visible="isLoading"
          :shading="true"
          :shadingColor="'rgba(0, 0, 0, 0.4)'"
          message="Loading..."
        />
        <DxScrolling column-rendering-mode="virtual" />
        <DxGroupPanel :visible="true" />
        <DxExport
          :enabled="true"
          :allow-export-selected-data="true"
          :fileName="'ValidatorDataGridExport'"
        />
        <DxColumnChooser :enabled="true" />
        <DxColumnFixing :enabled="true" />
        <DxToolbar>
          <DxItem name="groupPanel" />
          <DxItem name="columnChooserButton" />
          <DxItem name="exportButton" />
          <DxItem name="revertButton" />
          <DxItem name="saveButton" />
          <DxItem name="searchPanel" />
          <DxItem
            location="before"
            widget="dxButton"
            :disabled="false"
            :options="{
              icon: 'trash',
              text: 'Delete Selected (' + getSelectedCount() + ')',
              onClick: confirmDelete,
            }"
          />
          <DxItem
            location="before"
            widget="dxButton"
            :disabled="false"
            :options="{
              icon: 'clear',
              text: 'Clear Selection (' + getSelectedCount() + ')',
              onClick: clearSelection,
            }"
          />
        </DxToolbar>
      </DxDataGrid>
      <div
        class="row"
        style="
          width: 250px;
          margin-top: 35px;
          margin-left: 15px;
          margin-bottom: 15px;
        "
      >
        <button class="btn-default" @click="goBack">
          {{ $t("back_to_list") }}
        </button>
      </div>
    </card>
    <delete-confirmation-dialog
      :show="showDeleteConfirmationModal"
      @close="showDeleteConfirmationModal = false"
      @confirm="deleteSelectedRows"
      :title="$t('tools.import_validator.title')"
      :message="$t('tools.import_validator.delete_from_validated_data')"
    ></delete-confirmation-dialog>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxScrolling,
  DxLoadPanel,
  DxDataGridTypes,
  DxToolbar,
  DxItem,
  DxPaging,
  DxPager,
  DxSearchPanel,
  DxEditing,
  DxHeaderFilter,
  DxGroupPanel,
  DxExport,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
} from "devextreme-vue/data-grid";

import { DxButton } from "devextreme-vue/button";
import { successMessage, errorMessageList } from "~/helpers/common";
import { getValidatorGridColumns } from "./gridColumns";
import { mapActions, mapGetters } from "vuex";
import { format } from "date-fns";
import DeleteConfirmationDialog from "@/components/Modals/DeleteConfirmationDialog";

export default {
  name: "ImportValidator",
  components: {
    DxDataGrid,
    DxScrolling,
    DxLoadPanel,
    DxDataGridTypes,
    DxToolbar,
    DxItem,
    DxButton,
    DxPaging,
    DxPager,
    DxSearchPanel,
    DxEditing,
    DxHeaderFilter,
    DxGroupPanel,
    DxExport,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DeleteConfirmationDialog,
  },
  data() {
    return {
      selectedRowKeys: [],
      isLoading: false,
      gridColumns: this.getGridColumns(),
      gridData: this.importValidatedData ? this.importValidatedData : [],
      showDeleteConfirmationModal: false,
    };
  },
  computed: {
    ...mapGetters({
      importValidatedData: "importConfig/importValidatedData",
    }),
  },
  methods: {
    getSelectedCount() {
      return this.selectedRowKeys.length ?? 0;
    },
    ...mapActions({
      getImportValidatedData: "importConfig/getImportValidatedData",
      updateImportValidatedData: "importConfig/updateImportValidatedData",
    }),
    async loadImportValidatedData(data_import_id) {
      this.getImportValidatedData({ data_import_id: data_import_id });
    },
    getGridColumns() {
      const columns = getValidatorGridColumns();
      columns.map((column) => {
        if (column.caption) {
          column.caption = this.$t("tools.import_validator." + column.caption);
        }
        return column;
      });

      return columns;
    },
    formatDateColumns(changes) {
      changes.forEach((change) => {
        if (change.data) {
          if (change.data.start_date) {
            change.data.start_date = format(
              new Date(change.data.start_date),
              "yyyy-MM-dd HH:mm:ss"
            );
          }
          if (change.data.end_date) {
            change.data.end_date = format(
              new Date(change.data.end_date),
              "yyyy-MM-dd HH:mm:ss"
            );
          }
        }
      });
    },
    async updateImportValidatedDataWithCallback(changes) {
      try {
        const response = await this.updateImportValidatedData({ changes });
        successMessage(this, response.message);
        return true;
      } catch (e) {
        errorMessageList(this, e.errors);
        return false;
      }
    },
    onSaving(e) {
      if (e.changes.length) {
        this.formatDateColumns(e.changes);
        this.updateImportValidatedDataWithCallback(e.changes);
      }
    },
    async confirmDelete() {
      if (this.selectedRowKeys?.length > 0) {
        this.showDeleteConfirmationModal = true;
      }
    },
    async deleteSelectedRows() {
      this.showDeleteConfirmationModal = false;
      if (this.selectedRowKeys.length > 0) {
        const changes = [];
        this.selectedRowKeys.forEach((key) => {
          changes.push({ type: "remove", key: key });
        });

        this.updateImportValidatedDataWithCallback(changes).then((result) => {
          if (result) {
            const dataGridInstance = this.$refs.dataGrid.instance;
            if (dataGridInstance) {
              const dataSource = dataGridInstance.getDataSource();
              if (dataSource) {
                const store = dataSource.store();
                if (store) {
                  for (const key of this.selectedRowKeys) {
                    try {
                      store.remove(key);
                    } catch (error) {
                      console.error(
                        `Failed to remove item with key ${key}:`,
                        error
                      );
                    }
                  }
                }
                dataSource.reload();
              }
            }
            this.clearSelection();
          }
        });
      }
    },
    onSelectionChanged(e) {
      this.selectedRowKeys = e.selectedRowKeys;
    },
    clearSelection() {
      this.selectedRowKeys = [];
    },
    onExporting(e) {
      e.fileName = "SGPS_DASC_DataGridExport_"+ format(new Date(), "yyyy_MM_dd_hh_mm_ss");
      //const dataRows = e.component.getDataSource().items();
      //e.cancel = true; // Prevent default export
    },
    goBack() {
      this.$router.back();
    },
  },
  async mounted() {
    if (this.$route.query.import_id) {
      this.loadImportValidatedData(this.$route.query.import_id);
    }
  },
  watch: {
    importValidatedData: {
      handler: function (newVal, oldVal) {
        this.gridData = newVal;
      },
      deep: true,
    },
  },
};
</script>

<style>
#gridContainer {
  height: 960px;
}

#import-validator {
  padding: 15px;
}

.btn-default {
  background-color: #eeeded;
  color: #000;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 2px 2px;
  cursor: pointer;
  border: none;
  border-radius: 15px;
  padding: 10px 15px;

  &:hover {
    background-color: rgb(223, 221, 221);
    box-shadow: 0 0 8px rgba(223, 221, 221, 0.3);
  }
}

.selected-data {
  margin-top: 20px;
  padding: 17px;
  background-color: rgba(191, 191, 191, 0.15);
}

​ .selected-data .caption {
  font-weight: bold;
  font-size: 115%;
  margin-right: 4px;
}

.import-action-btn {
  margin-left: 20px;
  margin-top: -6px;
  float: right;
}

.disabled-btn {
  background-color: gray !important;
  cursor: not-allowed;
}
</style>
