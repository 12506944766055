<template>
     <DxTabPanel
        :element-attr="tabPanelAttributes"
        :data-source="consumSteps"
        :loop="false"
        :selected-item="currentItem"
        :selected-index="currentStep"
        :animation-enabled="true"
        :swipe-enabled="false"
        :show-nav-buttons="false"
        @selection-changed="tabSelectionChanged($event)"
    >
        <template #item="{ data: step }">
            <div>
                <div v-show="step == 1" class="consum_step consum_option_holder">
                    <div v-for="option in consumOptions" :key="option.code" class="consum_selector_option" @click="consumOptionClick(option)">{{option.title}}</div>
                </div>

                <div v-show="step == 2" class="consum_step_map">
                     <google-map
                        ref="googlemap"
                        :showMap="step == 2"
                        :selection_mode="true"
                        :season_uuid="season_uuid_local"
                        :initMapInMount="true"
                        mapClass="consum_map"
                        @showToastMessage="showToastMessage"
                        @removeSelectedParcel="removeSelectedParcel"
                        @addSelectedParcel="addSelectedParcel"
                        @showMapFilter="showMapFilter"
                    ></google-map>
                </div>
                <div v-show="step == 3" class="consum_step">
                    <search-input :parentClass="'consum_filter_search'" placeholder="Caută produs" @search="searchByText"></search-input>
                    <div class="consum-product-scroll warehouse_list_holder">
                        <stock-list ref="stockList" :filterData="filterData" :selectionMode="true" @productSelected="productSelected" :hideZeroStock="true"></stock-list>
                    </div>
                    <DxScrollView v-if="false" ref="consumProductScroll" class="consum-product-scroll warehouse_list_holder" :use-native="true">
                        <div v-for="wh in stockData" :key="wh.uuid" class="warehouse_item">
                            <div class="warehouse_title">
                                <div class="warehouse_title_image">
                                    <img src="@/assets/images/icons/warehouse_icon.svg"/>
                                </div>
                                <div class="warehouse_title_text">{{wh.warehouse_name}}</div>
                            </div>

                            <div class="product_list_holder">
                                <div v-for="(prod, idx) in wh.products" :key="prod.uuid" class="product_item" :last-item="wh.products.length - 1 == idx" @click="productClick">
                                    <div class="product_item_icon">
                                        <img src="@/assets/images/icons/samanta.svg"/>
                                    </div>
                                    <div class="product_item_name">{{prod.name}}</div>
                                    <div class="product_item_quantity">{{prod.quantity}}{{prod.mu_symbol}}</div>
                                </div>
                            </div>
                        </div>
                    </DxScrollView>
                    <div v-if="false" class="warehouse_list_holder">

                    </div>
                </div>
                <DxScrollView ref="consumInputScroll" v-show="step == 4" class="consum_step transfer-input-scroll" :use-native="true">
                    <div>
                        <div class="consum_input_holder"
                                :class="[{input_holder_error: $v.consumData.consum_date.$error}]">               
                            <div @click="showCalendarSelector">
                                <div class="input_label">Data</div>
                                <div class="input_value">
                                    {{ consumData.consum_date }}
                                </div>
                            </div>
                            <div v-if="is_date_selector_visible">
                                <FunctionalCalendar
                                    :date-format="'yyyy-mm-dd'"
                                    :is-date-range="false"
                                    v-model="calendarData"
                                    :change-month-function="true"
                                    :change-year-function="true"
                                    :is-date-picker="true"
                                    @choseDay="calendarDayChosen"
                                    @input="onDateSelected"
                                ></FunctionalCalendar>
                            </div>
                        </div>
                        <div class="consum_input_holder" @click="productAndWarehouseClick"
                            :class="[{input_holder_error: $v.consumData.product.uuid.$error}]">
                            <div class="input_label">Produs</div>
                            <div class="input_value">{{consumData.product.name}}</div>
                            <div class="consum_input_second_value">{{availableQuantity}} {{selectedMuSymbol}}</div>
                        </div>
                        <div class="consum_input_holder" @click="parcelsClick()">
                            <div class="input_label">Parcele</div>
                            <div class="input_value">{{getParcelNames}}</div>
                            <div class="consum_input_second_value">{{getSelectedParcelCountForInput}}</div>
                        </div>
                        <div class="consum_input_holder"
                            :class="[{input_holder_error: $v.consumData.quantity.$error}]">
                            <div class="input_label">Cantitate</div>
                            <div class="input_value">
                                <DxNumberBox
                                    placeholder="Introduceți cantitatea"
                                    :format="'#0.## ' + selectedMuSymbol"
                                    v-model="consumData.quantity"
                                    class="global_dx_number_input"
                                    :show-clear-button="false"
                                    value-change-event="keyup"
                                    :min="0"
                                    @value-changed="quantityChanged($event)"
                                /></div>
                                <div class="consum_input_second_value">Cost produs: {{productCost * consumData.quantity}} RON</div>
                        </div>
                        <div class="consum_input_holder" v-if="false">
                            <div class="input_label">Cost productir</div>
                            <div class="input_value">
                                <DxNumberBox
                                    placeholder="Introduceți prețul unitar"
                                    format="#,##0.00 RON"
                                    v-model="consumData.unitPrice"
                                    class="global_dx_number_input"
                                    :show-clear-button="false"
                                /></div>
                        </div>
                        <div class="consum_input_holder" @click="productAndWarehouseClick"
                            :class="[{input_holder_error: $v.consumData.warehouse.uuid.$error}]">
                            <div class="input_label">Depozit</div>
                            <div class="input_value">
                                <div class="input_value">{{consumData.warehouse.name}}</div>
                                <DxSelectBox v-if="false"
                                    v-model="consumData.warehouse.uuid"
                                    :search-enabled="false"
                                    search-mode="contains"
                                    search-expr="name"
                                    class="input_value dx_input_value"
                                    placeholder="Alegeți depozit"
                                    :data-source="warehouses"
                                    display-expr="name"
                                    value-expr="uuid"
                                    :show-clear-button="false"
                                    drop-down-button-template="imageIcon"
                                    >
                                        <template #imageIcon>
                                        <div>
                                            <div class="dx_combo_open_button" v-show="!consumData.warehouse.uuid || consumData.warehouse.uuid == ''">
                                                <img src="@/assets/images/icons/plus_green.svg"
                                                class="custom-selector-icon"
                                            ></div>
                                            <div class="dx_combo_open_button" v-show="consumData.warehouse.uuid && consumData.warehouse.uuid.length > 0">
                                                <img src="@/assets/images/icons/edit.svg"
                                                class="custom-selector-icon"
                                            ></div>
                                        </div>
                                    </template>
                                    <template #item="{data}">
                                        <div class="dx_custom_combo_item">{{data.name}}</div>
                                    </template>
                                    </DxSelectBox>
                            </div>
                        </div>
                        <div class="consum_input_holder">
                            <div class="input_label">Observații</div>
                            <div class="input_value">
                                <DxTextArea v-model="consumData.notes" class="global_dx_number_input" />
                            </div>
                        </div>
                        <div class="input-bottom-spacer"></div>
                    </div>
                </DxScrollView>
                <div class="consum_bottom_sticky" v-show="step == 2 && selectedLayers.length > 0">
                    <div class="sticky_action_button" @click="continueFromMap">Continuă</div>
                </div>
                <div class="save_bottom_sticky_two_line" v-show="step == 4">
                    <div class="sticky_action_button" @click="saveConsum(true)">Salvează și consum nou</div>
                    <div class="sticky_action_button_second" @click="saveConsum(false)">Salvează</div>
                </div>
                <toast-message ref="toastMessage"></toast-message>
            </div>
        </template>
    </DxTabPanel>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import { ConsumTypes, ConsumOptions } from "./consum.js"
import DxTabPanel from "devextreme-vue/tab-panel";
import ToastMessage from "../../../../components/Mobile/toastMessage.vue"
import GoogleMap from "@/components/Map/GoogleMap.vue";
import SearchInput  from "@/components/Mobile/searchInput.vue"
import { DxScrollView } from "devextreme-vue/scroll-view";
import DxNumberBox from "devextreme-vue/number-box";
import DxSelectBox from "devextreme-vue/select-box";
import StockList from '../../partials/stockList.vue'
import { required, helpers } from "vuelidate/src/validators";
import DxTextArea from 'devextreme-vue/text-area';
import { FunctionalCalendar } from "vue-functional-calendar";
import moment from "moment";


const requiredByParam = (param) => (value) => {
    return helpers.req(value) && value <= param;
};

export default {
    name: "Consum",
    data(){
        return {
            calendarData: {},
            consumSteps: [1,2,3,4],
            currentStep: 0,
            currentItem: 1,
            currentConsumOption: {},
            tabPanelAttributes: {
                class: 'consum_tabs'
            },
            selectedLayers: [],
            season_uuid_local: '',
            currentSeason: {},
            stockData: [],
            warehouses: [],
            consumData: {
                product: {
                    uuid: '',
                    name: ''
                },
                parcels: [],
                parcel_names: '',
                quantity: null,
                unitPrice: null,
                warehouse: {
                    uuid: '',
                    name: ''
                },
                notes: '',
                consum_date: null
            },
            filterData:{
                name: '',
            },
            availableQuantity: 0.00,
            selectedMuSymbol: '',
            productCost: 0.00,
            is_date_selector_visible: false
        };
    },
     validations() {
        return {
            consumData:{
                product:{
                    uuid:{required}
                } ,
                warehouse:{
                    uuid:{required}
                },
                quantity: {requiredByParam:requiredByParam(this.availableQuantity)},
                consum_date: {required}
            }
        };
    },
    components: {
        DxTabPanel,
        ToastMessage,
        GoogleMap,
        SearchInput,
        DxScrollView,
        DxNumberBox,
        DxSelectBox,
        StockList,
        DxTextArea,
        FunctionalCalendar
    },
    computed: {
        ...mapGetters({
            currentDepartment: "auth/currentDepartment",
            seasons: 'seasons/seasons',
        }),
        consumTypes(){
            return ConsumTypes;
        },
        consumOptions(){
            return ConsumOptions;
        },
        getSelectedParcelSurfaceDecimal() {
            return (this.selectedLayers && this.selectedLayers.length > 0 ? this.selectedLayers.map(l => l.surface).reduce((x, y) => { return (parseFloat(x) + parseFloat(y)).toFixed(2);}) : 0);
        },
        getSelectedParcelSurface() {
          return "Ai selectat " + this.getSelectedParcelSurfaceDecimal + " hectare cu ";
        },
        getSelectedParcelCount() {
            return (
                "" +
                this.selectedLayers.length +
                (this.selectedLayers.length == 1 ? " parcelă" : " parcele")
            );
        },
        getSelectedParcelCountForInput() {
            return (
                "" +
                this.selectedLayers.length +
                (this.selectedLayers.length == 1 ? " parcelă selectată" : " parcele selecatte") +
                " (" + this.getSelectedParcelSurfaceDecimal + " ha)"
            );
        },
        getParcelNames(){
            return this.selectedLayers.map(l => l.name).join(', ');
        }
    },
    watch: {
        seasons: {
            handler: function(newVal) {
                this.setCurrentSeason(newVal);
            }
        }
    },
    methods: {
         ...mapActions({
            getStockData: 'smStocks/getSmStocks',
            getDepartments: 'company/getDepartments',
            getSeasons: "seasons/getSeasons",
            getWarehouses: 'smWarehouses/getSmWarehouses',
            createConsum: 'smTransactions/createConsumTransaction',
            getStockProductInfo: 'smStocks/getSmStockProductInfo'
        }),
        tabSelectionChanged(e) {
          this.currentItem = e.addedItems[0];
          this.currentStep = this.consumSteps.indexOf(this.currentItem);
          this.changeAddButtonVisibility();
        },
        changeAddButtonVisibility(){
            //this.$emit('changeAddButtonVisibility', this.currentStep == 1 && this.detailStepCode != this.detailCodes.Warehouse);
        },
         goBack(){
            if(this.currentStep > 0){
                this.currentStep = this.currentStep - 1;
                this.setTitle(this.currentStep == 0);
            }
            else{
                this.$emit('goBackParent');
            }
        },
        setTitle(){
            if(this.currentStep == 0) {
                this.$emit('setTitle', 'Consum', false);
            }
             if(this.currentStep >= 1) {
                if(this.currentStep == 1){
                    this.$emit('setTitle', 'Consum produs', true, this.getSelectedParcelSurface, this.getSelectedParcelCount);
                }
                else{
                    this.$emit('setTitle', 'Consum produs', true);
                }
            }
        },
        consumOptionClick(option){
            if(option.code == this.consumTypes.Parcel){
                this.currentConsumOption = option;
                this.currentStep = this.currentStep + 1;
                this.setTitle();
            }
            else{
                this.$refs.toastMessage.showInfo('Funcția este în curs de dezvoltare!');
            }
        },
        removeSelectedParcel(layer) {
            this.selectedLayers.splice(this.selectedLayers.indexOf(layer.uuid), 1);
            this.setTitle();
        },
        addSelectedParcel(layer) {
            layer.surface = Number.parseFloat(layer.surface);
            this.selectedLayers.push(layer);
            this.setTitle();
        },
        showMapFilter(){},
        showToastMessage(type, message){
            this.$refs.toastMessage.showToastMessage(type, message);
        },
        continueFromMap(){
            this.currentStep = this.currentStep + 1;
            this.setTitle();
        },
        setCurrentSeason(seasons){
            if(seasons.length === 0) return;

            this.currentSeason = seasons[0];
            seasons.forEach((obj) => {
                if(new Date(obj.created_at) > new Date(this.currentSeason.created_at)){
                this.$set(this, 'currentSeason', obj);
                }
            });
            this.season_uuid_local = this.currentSeason.uuid;
        },
        searchByText(searchText){
            this.$set(this.filterData, 'name', searchText);
        },
        productClick(){
            this.currentStep = this.currentStep + 1;
            this.setTitle();
        },
        parcelsClick(){
            this.currentStep = this.currentStep - 2;
            this.setTitle();
        },
        productSelected(stock_data, warehouse){
            this.consumData.product.uuid = stock_data.uuid;
            this.consumData.product.name = stock_data.name;
            this.consumData.warehouse.uuid = warehouse.warehouse_uuid;
            this.consumData.warehouse.name = warehouse.warehouse_name;
            this.availableQuantity = warehouse.quantity;
            this.selectedMuSymbol = stock_data.measure_unit.symbol;
            this.currentStep = this.currentStep + 1;
            this.setTitle();

        },
        productAndWarehouseClick(){
            this.currentStep = this.currentStep - 1;
            this.setTitle();
        },
        saveConsum(saveAndNew){
            this.$v.consumData.$touch();
            if (!this.$v.consumData.$invalid) {
                let consumDataForSave ={
                    department_uuid: this.currentDepartment.department_uuid,
                    warehouse_uuid: this.consumData.warehouse.uuid,
                    product_uuid: this.consumData.product.uuid,
                    quantity: this.consumData.quantity,
                    //unit_price: 1,
                    parcels: [],
                    notes: this.consumData.notes,
                    consum_date: this.consumData.consum_date
                };

                this.selectedLayers.forEach(l => {
                    if(l.parcel_uuid && l.parcel_uuid.length > 0){
                        consumDataForSave.parcels.push({uuid: l.parcel_uuid});
                    }
                });

                this.createConsum(consumDataForSave).then(response => {
                    this.$refs.toastMessage.showSuccess('Consumul a fost salvat cu succes!');
                    if(saveAndNew){
                        this.resetForm();
                    }
                    else{
                        this.closeTransactions('Consumul a fost salvat cu succes!');
                    }
                }).catch((e) => {
                    this.$refs.toastMessage.showError('A apărut o erroare la salvarea consumului. Contactați administratorul de sistem!');
                });
            }
            else{
                 if(this.$v.consumData.quantity.$invalid){
                    this.$refs.toastMessage.showError("Cantitatea trebuie să fie înre 0 și " + this.availableQuantity + "!");
                }
            }

        },
        resetForm(){
            this.$v.$reset();
            this.productCost = 0.00;
            this.consumData = {
                product: {
                    uuid: '',
                    name: ''
                },
                parcels: [],
                parcel_names: '',
                quantity: null,
                unitPrice: null,
                warehouse: {
                    uuid: '',
                    name: ''
                },
                notes: ''
            };
           this.productAndWarehouseClick();
        },
        closeTransactions(message){
            this.$emit('closeTransactions', message);
        },
        quantityChanged(e){
            if(this.consumData.quantity != null){
                let getStockInfodata = {
                    product_uuid: this.consumData.product.uuid,
                    warehouse_uuid: this.consumData.warehouse.uuid,
                    quantity: this.consumData.quantity
                }
                this.getStockProductInfo(getStockInfodata).then(response => {
                    this.productCost = response.price_net;
                    this.$set(this, 'productCost', response.price_net);
                });
            }
        },
        showCalendarSelector(){
            this.is_date_selector_visible = !this.is_date_selector_visible;
        },
        calendarDayChosen(data){
            this.is_date_selector_visible = false;
        },
        onDateSelected(date) {
            this.consumData.consum_date = date.selectedDate;
        }
    },
    mounted(){
        this.consumData.consum_date = moment().format('YYYY-MM-DD');
        this.getSeasons({"department_uuid": this.currentDepartment.department_uuid});
        this.setTitle();
         this.getWarehouses({}).then(response => {
            this.warehouses = response.data;
        });
    }
}
</script>

<style lang="scss">
  @import "@/assets/scss/stockMobile/consum.scss";
</style>
