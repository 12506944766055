<template>
  <card :title="title" id="details" v-if="canEdit || canDelete" v-cloak>
    <partner-details-template
      :data="data"
      ref="partnerDetailsTemplate"
    ></partner-details-template>
    <agro-action-buttons
      :data="data"
      :can-edit="canEdit"
      :can-delete="canDelete"
      @onSave="save"
      @onSaveAndNew="saveAndNew"
      :save-and-new="true"
      @onDelete="confirmDelete"
      @onGotoList="gotoList"
    ></agro-action-buttons>

  </card>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import * as actions from '@/helpers/generalActions'
import AgroActionButtons from "@/components/AgroActionButtons/index";
import Forbidden from "@/pages/errors/Forbidden";
import PartnerDetailsTemplate from "~/pages/stock-management/sm-partners/partials/PartnerDetailsTemplate.vue";

export default {
  name: 'SMPartnersDetails',
  components: {PartnerDetailsTemplate, Forbidden, AgroActionButtons},
  data() {
    return {
      data: {},
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      users: 'user/users',
      partners: 'smPartners/smPartners',
    }),
    name() {
      return this.$t('stock_management.partners.title')
    },
    config() {
      return {
        permission: permissions.SM_PARTNERS,
        permission_type: permissionTypes.module,
        get: this.get,
        create: this.create,
        update: this.update,
        delete: this.delete,
        list_url: '/stock-management/sm-partners',
        confirm_delete: true,
      }
    },
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    title() {
      if (this.data.uuid) {
        return this.$t('stock_management.partners.edit');
      }
      return this.$t('stock_management.partners.create_new');
    },
    $v() {
      return this.$refs['partnerDetailsTemplate'].validator;
    }
  },
  methods: {
    ...mapActions({
      get: 'smPartners/getSmPartner',
      create: 'smPartners/createSmPartner',
      update: 'smPartners/updateSmPartner',
      delete: 'smPartners/deleteSmPartner'
    }),
    loadData() {
      let vm = this;
      return this.actions.getData(this, (data) => {
        vm.$set(vm.data, 'partner_type', null);
        vm.$set(vm,'is_active', true);
      }, (data) => {
        vm.$set(vm,'is_active', data.is_active === 1);
      });
    },
    gotoList() {
      return actions.gotoList(this);
    },
    confirmDelete() {
      return this.actions.confirmDelete(this);
    },
    save() {
      return this.actions.save(this, this.config.list_url);
    },
    saveAndNew() {
        return this.actions.saveAndNew(this, '/stock-management/sm-partners/add');
    },
    newObject() {
      return this.actions.newObject(this);
    },
  },
  mounted() {
    this.loadData();
    this.actions.handleParams(this);
  },
  watch: {
    $route: {
      handler: function(newVal) {
        this.actions.handleParams(this);
      },
      deep: true,
    },
    permission: {
      handler: function(newVal) {
        this.permissionModule = newVal;
      },
      deep: true,
    },
  },

}
</script>

<style scoped>

</style>
